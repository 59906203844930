import { useCallback } from 'react';
import { AnimatePresence, m } from 'framer-motion';
// routes
import {
  // usePathname,
  useRouter,
} from 'next/navigation';
// import { paths } from '@/routes/paths';
// @mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
// components
import Iconify from '@/components/iconify/iconify';
// hooks
import { useOffSetTop } from '@/hooks/use-off-set-top';
// utils
import { detectBrowserName } from '@/utils/browser';

// ----------------------------------------------------------------------

type Props = {
  enabledBackground?: boolean;
  enabledBack?: boolean;
  toptitle?: boolean;
  fullWidth?: boolean;
  title?: string;
  subtitle?: string;
  sx?: SxProps<Theme>;
  headContent?: React.ReactNode;
  backHeadSx?: SxProps<Theme>;
  backTo?: string;
  children: React.ReactNode;
  onBack?: VoidFunction;
};

export default function CompactLayout({
  enabledBackground,
  enabledBack,
  toptitle,
  fullWidth,
  title,
  subtitle,
  headContent,
  backHeadSx,
  backTo,
  sx,
  onBack,
  children,
}: Props) {
  const { back, replace } = useRouter();
  // const pathName = usePathname();

  const browserName = detectBrowserName();

  const enabledHead = !!title || !!headContent;

  const offset = useOffSetTop(80);

  const goBack = useCallback(() => {
    if (backTo) {
      replace(backTo);
    } else {
      back();
    }
  }, [back, backTo, replace]);

  const renderBackHead = enabledBack ? (
    <Stack
      component={m.div}
      initial={{ y: -80, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.32 }}
      direction="row"
      spacing={0.5}
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        pt: 2,
        pr: 2,
        pb: 1,
        alignItems: 'center',
        width: 1,
        bgcolor: (theme) => theme.palette.background.default,
        boxShadow: (theme) => (offset ? theme.customShadows.z24 : 'none'),
        zIndex: 999,
        ...backHeadSx,
      }}
    >
      <Button
        variant="text"
        color="inherit"
        sx={{
          width: 35,
          height: 35,
          minWidth: 0,
          padding: 0.5,
          ml: 0.5,
        }}
        onClick={typeof onBack === 'function' ? onBack : goBack}
      >
        <Iconify icon="ion:chevron-back" />
      </Button>
      {toptitle && (
        <Typography variant="h4" sx={{ fontSize: '1.125rem', mt: { xs: 0.25, sm: 0 } }}>
          {title}
        </Typography>
      )}
    </Stack>
  ) : null;

  const renderHead =
    enabledHead && !toptitle ? (
      <Stack
        sx={{
          mb: 2,
          textAlign: 'left',
          ...(headContent
            ? {
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
                bgcolor: (theme) => theme.palette.background.default,
                zIndex: 999,
                boxShadow: (theme) => (offset ? theme.customShadows.z24 : 'none'),
              }
            : {
                minHeight: 150,
              }),
        }}
      >
        {headContent ? (
          <>{headContent}</>
        ) : (
          <>
            <Typography variant="h3" paragraph sx={{ fontSize: '1.65rem' }}>
              {title}
            </Typography>

            <Typography color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
              {subtitle}
            </Typography>
          </>
        )}
      </Stack>
    ) : null;

  return (
    <Box
      sx={{
        ...(enabledBackground
          ? {
              backgroundImage: 'url(/assets/background/overlay_5.jpg)',
              backgroundPositionX: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : null),
      }}
    >
      <Box
        sx={{
          ...(enabledBackground && browserName !== 'safari'
            ? { backdropFilter: 'blur(2px)' }
            : null),
        }}
      >
        <Container component="main" sx={{ ...(fullWidth ? { px: 0 } : null) }}>
          <AnimatePresence>
            <Stack
              component={m.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              spacing={0}
              sx={{
                m: 'auto',
                maxWidth: 400,
                minHeight: '100vh',
                textAlign: 'center',
                position: 'relative',
                ...(enabledHead
                  ? {
                      pt: 10,
                      pb: 6,
                    }
                  : {
                      justifyContent: 'center',
                      py: 6,
                    }),
                ...sx,
              }}
            >
              {renderBackHead}

              {renderHead}

              {children}
            </Stack>
          </AnimatePresence>
        </Container>
      </Box>
    </Box>
  );
}
