'use client';

// layout
import CompactLayout from '@/layouts/compact/layout';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// routes
import { RouterLink } from '@/routes/components';
import { paths } from '@/routes/paths';
// components
import Logo from '@/components/logo';

// ----------------------------------------------------------------------

export default function GuestMainView() {
  return (
    <CompactLayout enabledBackground>
      <Box flexGrow={1} />

      <Logo text disabledLink sx={{ my: 5 }} />

      <Box flexGrow={1.5} />

      <Stack spacing={1.5}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ border: 'solid 1px #FFF' }}
          component={RouterLink}
          href={paths.starter}
        >
          REGISTRARME
        </Button>
        <Button
          component={RouterLink}
          href={paths.auth.login}
          variant="text"
          fullWidth
          sx={{ color: '#FFF' }}
        >
          INICIAR SESIÓN
        </Button>
      </Stack>
    </CompactLayout>
  );
}
